export const resetAbaProdutos = {
  leitor: null,
  busca_por: { label: '', value: '' },
  qtd_produto: null,
  discount: null,
  val_unit: '',
};

export const resetAbaFinalizacao = {
  num_cpf_cnpj: '',
  busca_parceiro: { label: '', value: '' },
  des_observacao: '',
};
